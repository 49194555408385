import React from "react";
import "./hero.less";

export const NewBanner = ({ BannerImg, title }) => {
  const style= title === 'Permanent Placements'?{
    height:'33em'
  }:{}
  const style2= title === 'Permanent Placements'?{
    objectPosition:'left'
  }:{}
  return (
    <div className="hero-box" style={style} >
      <img loading="lazy"  className="hero-img" src={BannerImg} alt={title} title={title} style={style2} />
      <p>Our Solutions</p>
      <div className="overlay"></div>
    </div>
  )
}
