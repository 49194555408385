import React from "react";
import { Link } from "gatsby";
import { Col, Row } from "antd";
import { PageContentEmployerScreen } from "../../../components/PageContent/PageContent";
import { NewBanner } from "../../../components/Solutions/newBanner";
import Sidebar from "./sidebar";
import BreadcrumbSec from "./Breadcrumb";
import CandidateImg from "../../../images/solutions/PP/Candidate-Database.webp";
import skillSImg from "../../../images/solutions/PP/skills.webp";
import HiringImg from "../../../images/solutions/PP/hiring.webp";
import banImg from "../../../images/solutions/banner/Banner-Perm.webp";
import "./common.less";
import Stories from "../../../components/employerHomePage/stories";

const PermanentRecruitment = props => {
  return (
    <PageContentEmployerScreen location={props.location}>
      <NewBanner BannerImg={banImg} title={"Permanent Placements"} />
      <BreadcrumbSec current={"Permanent Placements"} />

      <section className="mainSolutionSection px-3 px-md-6">
        <Row>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} className="float-right">
            <div className="box">
              <div className="heading">
                <h1>Permanent Placements</h1>
                <span className="separator"></span>
              </div>
              <div className="d-flex align-items-center pb-3">
                <h2 className="p-0 m-0 text-bold">
                  We are all about people. Permanently{" "}
                </h2>
              </div>
              <p className={""}>
                We understand that the competition for the best talent is
                fierce. Although every company is unique, organisations mostly
                need the same thing: committed, productive, and valuable
                employees. In today's highly competitive job market, employers
                need to be creative and empathetic to find the right fit talent
                to best fit their business objectives. The focus is on selecting
                talent rather than selecting people.
              </p>
              <p>
                Our permanent recruitment services help connect the best
                professional talent by searching for and putting forward
                candidates for permanent roles within your organisation. In the
                process, you get to harness new tools and techniques and the
                latest developments in permanent recruitment services innovation
                ensuring you can hire the right tech talent, effectively and
                efficiently. We monitor the trending recruitment strategies such
                as workforce mobility, skills in demand, and ensure we have the
                most current systems in place to allow us to source and match
                the right people for the right roles.
              </p>
              <p className={"text-danger text-bold"}>
                Our permanent placement services are fortified by unique
                assessment tools and platforms which help match candidates to
                roles, by assessing their potential fit with an organisation’s
                culture and setting target candidate profiles.{" "}
              </p>
              <div className="solution-icons">
                <h2 className="title">
                  Leveraging our strengths to provide real value to clients{" "}
                </h2>
                <div className="icon-sec justify-space-evenly">
                  <div className="icon">
                    <img loading="lazy" 
                      src={CandidateImg}
                      alt="CandidateImg"
                      style={{ width: "70px" }}
                    />
                    <h3>Extensive database of active candidates </h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy" 
                      src={skillSImg}
                      alt="skillSImg"
                      style={{ width: "70px" }}
                    />
                    <h3>
                      Wide range of skills ranging from vanilla to niche to
                      super niche{" "}
                    </h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy" 
                      src={HiringImg}
                      alt="HiringImg"
                      style={{ width: "70px" }}
                    />
                    <h3>
                      Skill based hiring driven by technology and industry
                      specific subject matter experts and recruiters{" "}
                    </h3>
                  </div>
                </div>
              </div>
              <p>
                We become an extension of your talent acquisition team, working
                closely with your hiring managers to understand your brand ethos
                and culture, providing you with the benefits of improving the
                effectiveness of the recruitment process, and ultimately your
                organisation’s productivity. We can cover all or just part of
                the recruitment life cycle, enabling you when and where you need
                it.
              </p>
              <p>
                We are consistently human-centric in our approach. While we
                continue to adopt technological innovations and recruitment
                technology, such as specific tools for the selection or instant
                matching of very specific profiles. But we believe more in
                talent. We believe the focus should be on diversity and
                inclusion by "screening" talent from a variety of backgrounds
                and perspectives, rather than "screening people". We strive to
                ensure that everyone gets the opportunities they deserve and
                that no one is left behind. As one of the best permanent
                placement services companies, we don't just help employers find
                good recruits. We work closely with candidates at every stage of
                the recruitment process, and we stay in touch with them long
                after their contract has been signed. We understand the
                professional aspirations of job seekers and respond to them
                effectively. Our exceptional support and guidance throughout
                each stage of the recruitment process make us one of the best in
                the industry.{" "}
              </p>
              <div className="text-center">
                <Link
                  to="/employer/business-enquiry/"
                  state={{ interested: "Permanent Placements" }}
                >
                  Business Enquiry
                </Link>
              </div>
            </div>
          </Col>
          <Sidebar title={'Permanent Placements'} />
        </Row>
        <Stories heading="Case Study" isReadMore={false} />
      </section>
    </PageContentEmployerScreen>
  )
}

export default PermanentRecruitment
